import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#nadcha"> &gt; Radca(NÁDCHA)</a>
            <a
              href="/radca/nadcha/zvladnite_zapal_prinosovych_dutin/"
              className="sel"
            >
              {" "}
              &gt; Zvládnite zápal prinosových dutín
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_181681637.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Zvládnite zápal prinosových dutín</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    <b>
                      Silné tlaky v oblasti čela, dlho pretrvávajúca nádcha,
                      hnisavý výtok z nosa, vysoká horúčka – to sú základné
                      príznaky sprevádzajúce zápal prinosových dutín, s ktorými
                      sa borí veľa ľudí (pričom často svoje ťažkosti ani
                      nepoznajú).
                    </b>
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Prinosové dutiny (vzduchom naplnené priestory vo vnútri
                    tvárovej časti lebky) zabezpečujú tepelnú ochranu mozgu a
                    očníc, zodpovedajú za správny prenos hlasu (vedú a prijímajú
                    zvuky, modulujú hlas) a plnia aj respiračnú funkciu
                    (ohrievajú, čistia a zvlhčujú vdychovaný vzduch). S nosovou
                    dutinou sú spojené prirodzenými otvormi v jej bočnej stene.
                    Ak dôjde k upchatiu prinosových dutín a hlien z nich už
                    nemôže voľne vytekať, naruší sa ich funkcia, objaví sa
                    bolesť a začína sa zápal.{" "}
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Zápal prinosových dutín je spôsobený bakteriálnou infekciou.
                    Baktérie postupne upchajú spojenia nosovej dutiny s
                    prinosovými dutinami a vyvolajú opuch, čím vznikne podtlak v
                    oblasti čela, ktorý je škodlivý pre nosovú sliznicu. V
                    záujme zníženia rizika zápalu prinosových dutín je vhodné
                    dodržiavať niekoľko zásad. Pri nádche je veľmi dôležité
                    odstraňovať hlieny z upchatého nosa (ich nahromadenie
                    blokuje orgány spojené s ústím nosovej dutiny). Nemenej
                    dôležité je vyhýbať sa nečistotám vo vzduchu (napr.
                    cigaretovému dymu, škodlivým chemickým zlúčeninám a peľu),
                    ktoré môžu spôsobovať podráždenie. Nesmieme zabúdať ani na
                    správnu vlhkosť vzduchu, pravidelné čistenie filtrov v
                    klimatizácii, pitie veľkého množstva tekutín a vyhýbanie sa
                    látkam spôsobujúcim precitlivenosť (alergické reakcie
                    najčastejšie vyvolávajú: jaseň, lipa, jelša a lieska).{" "}
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    &nbsp;
                    <img
                      alt="Horúčka, sekrécia, nádcha - príznaky zápalu vedľajších nosových dutín. Nájdite riešenia OLYNTH®."
                      src="/web/kcfinder/uploads/images/shutterstock_575462908.jpg"
                      style={{
                        width: "380px",
                        height: "253px",
                        float: "left",
                        margin: "5px 10px 10px 0px",
                        "border-width": "2px",
                        "border-style": "solid"
                      }}
                    />
                    <span className="s1">
                      Na upchaté a bolestivé prinosové dutiny pomôžu aj domáce
                      metódy. Úľavu prináša obklad z jódovo-brómovej soli, ktorý
                      urýchľuje proces čistenia nosa a prinosových dutín. V
                      mnohých domácnostiach sa používajú hrejivé masti, obklady
                      z teplého hrachu alebo horčice a vdychovanie záparu z
                      cibule, cesnaku a čerstvo nastrúhaného chrenu (s cieľom
                      vynútiť kýchanie, čiže prečistenie nosa a prinosových
                      dutín). Medzi prírodné metódy boja so zápalom prinosových
                      dutín patria aj inhalácie s prídavkom byliniek a
                      éterických olejov. Zápary zmierňujú bolesť a riedia
                      hlieny, ktoré sa vďaka tomu dajú účinne odstrániť.
                      Neznižujú však prekrvenie nosovej sliznice, pretože
                      vyvolávajú len potenie.{" "}
                    </span>
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Domáce spôsoby liečby nádchy je vhodné doplniť príslušnými
                    liekmi. Môžeme siahnuť po nosových roztokových
                    aerodisperziách OLYNTH<sup>®</sup>, ktoré vďaka obsahu{" "}
                    <a href="/slovnik_pojmov/#xylometazolin" target="_blank">
                      xylometazolínu
                    </a>
                    &nbsp;sťahujú cievky nosovej sliznice, zmierňujú jej opuch a
                    uľahčujú dýchanie nosom.{" "}
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Ak pozorované príznaky zápalu prinosových dutín neustupujú
                    alebo sú ešte výraznejšie, je nevyhnutné požiadať o pomoc
                    laryngológa. Lekárske vyšetrenie pomôže dôkladne zanalyzovať
                    problém a lekár rozhodne o ďalšom postupe.{" "}
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p className="p1">
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">Literatúra:</span>
                  </span>
                </p>
                <p className="p1">
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">
                      Choroby ucha, nosa i gardła z chirurgią głowy i szyi,
                      opracowanie zbiorowe, Wydawnictwo Medyczne Urban &amp;
                      Partner, 2012.
                    </span>
                  </span>
                </p>
                {/*<p className="p1">
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">
                      Mieczkowski Daniel,{" "}
                      <a
                        href="http://www.nowoczesna-klinika.pl/pl/artykuly/17/Analizy_Raporty/1/284/Co_drugi_Polak_mial_klopoty_z_zatokami"
                        target="_blank"
                      >
                        <em>Co drugi Polak miał kłopoty z zatokami </em>
                      </a>
                      [online], [prístup: 15. apríla 2016], prístupné na
                      internete:{" "}
                    </span>
                  </span>
                  </p>*/}
                <p className="p2">&nbsp;</p>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/nadcha/ako_si_spravne_cistit_nos/"
                  className="box"
                >
                  <span className="photo" style={{backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/jak_prawidlowo_oczyszczac_nos.png)"}} />
                  <span className="title">
                    Ako si správne čistiť nos pri nádche?
                  </span>
                </a>
                <a href="/radca/nadcha/spoznajte_typy_nadchy/" className="box">
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/poznaj_rodzaje_kataru.png)"}} />
                  <span className="title">Spoznajte typy nádchy</span>
                </a>
                <a
                  href="/radca/nadcha/zabojujte_s_nadchou_rychlo/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/wylecz_katar_szybko_skutecznie.png)"}} />
                  <span className="title">Zabojujte s nádchou rýchlo</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
